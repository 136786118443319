import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SEO from 'components/utils/seo';
import Layout from 'components/utils/layout';
import GridView from 'components/utils/gridView';
import RecentPostBox from 'components/modules/recentPostBox';
import WideSection from 'components/atoms/wideSection';
import HighlightedText from 'components/atoms/highlightText';
import AnimatedLogo from 'components/modules/animatedLogo';
import AuthorInfoBox from 'components/modules/authorInfoBox';

const StyledWideSection = styled(WideSection)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  h2 {
    color: ${({ theme }) => theme.color.light};
  }
`;

const StyledSection = styled.section`
  margin-top: ${({ negativespacing }) => (negativespacing ? '-15rem' : 0)};
  padding: 5rem 2rem;
  border-radius: 2rem;
  z-index: 50;
  text-align: center;
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: repeat(4, minmax(25rem, min-content));
  h2 {
    text-align: center;
    grid-column: 1 / -1;
  }
  ${({ theme: { mq } }) => mq.mediumDesktop} {
    padding: 0 5rem;
  }
`;

const LogoInfoBox = styled.div`
  margin-top: 10rem;
  position: relative;
  grid-column: 1 / -1 !important;
  justify-self: center;
  z-index: 0;
  @media (min-width: 1168px) {
    width: 95%;
  }

  ${({ theme: { mq } }) => mq.desktop} {
    width: 90%;
  }
  ${({ theme: { mq } }) => mq.bigTablet} {
    width: 61rem;
    margin: -5rem;
  }
  ${({ theme: { mq } }) => mq.tablet} {
    width: 55rem;
    margin: -15rem 10rem;
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    width: 45;
    margin: -10rem 5rem -15rem;
  }
  ${({ theme: { mq } }) => mq.phone} {
    width: 45;
    margin: -15rem 5rem -20rem;
  }
`;

const StyledParagraph = styled.p.attrs(props => ({
  className: 'about-paragraph',
}))`
  font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
  text-align: left;
  margin-bottom: 10rem;
  padding: 2rem;
  position: relative;
  background-color: ${({ theme }) => theme.color.devider};

  &:nth-of-type(odd) {
    border-left: 5px solid ${({ theme }) => theme.color.active};
  }
  &:nth-of-type(even) {
    border-right: 5px solid ${({ theme }) => theme.color.active};
  }

  :first-of-type {
    grid-column: 2/5;
    border-radius: 0 2rem 2rem 0;
  }
  :nth-of-type(2) {
    grid-column: 1/3;
    border-radius: 2rem 0 0 2rem;
  }
  :nth-of-type(3) {
    grid-column: 2/4;
    border-radius: 0 2rem 2rem 0;
  }

  :nth-of-type(odd) {
    ::before {
      content: '';
      position: absolute;
      width: 78%;
      height: 5px;
      top: 50%;
      left: -78%;
      background-color: ${({ theme }) => theme.color.active};
    }
  }
  :nth-of-type(even) {
    ::after {
      content: '';
      position: absolute;
      width: 78%;
      height: 5px;
      top: 50%;
      right: -78%;
      background-color: ${({ theme }) => theme.color.active};
    }
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    grid-column: 1 / -1 !important;

    :nth-of-type(2) {
      padding-left: 3rem;
    }
  }
`;

const StyledHeading = styled.h3`
  margin: 5rem 0 5rem;
  text-align: center;
`;

const AboutPage = () => {
  const infoWrapper = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const allParagraphs = infoWrapper.current.querySelectorAll(
      '.about-paragraph',
    );
    const allParagraphsArr = gsap.utils.toArray(allParagraphs);

    allParagraphsArr.forEach((paragraph, index) => {
      const startPositon = index % 2 === 0 ? '-=200' : '+=200';
      gsap.from(paragraph, {
        duration: 0.5,
        delay: 0.5,
        x: startPositon,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: paragraph,
          start: 'top bottom',
        },
      });
    });
  });

  return (
    <Layout>
      <SEO title='O mnie' image='logo' />
      <GridView>
        <StyledWideSection>
          <LogoInfoBox>
            <AnimatedLogo />
          </LogoInfoBox>
        </StyledWideSection>
        <StyledSection negativespacing ref={infoWrapper}>
          <h2>Czym jest Koncepcja Na Sukces?</h2>
          <StyledParagraph>
            <HighlightedText>Koncepcja na Sukces</HighlightedText> - Projekt,
            który powstał w Listopadzie 2017 roku z chęci podzielenia się z
            szerszym gronem osób swoimi doświadczeniami, początkowo jako grupa
            na Fb, która swoją obecną nazwę dostała 13.04.2018 roku. Fanpage
            rozpoczął swoje istnienie 14.10.2018. Ewolucja trwała, żeby w końcu
            na początku 2020 roku Koncepcja Na Sukces przeszła rebranding,
            którego końcowym efektem jest ten właśnie Blog, a co najważniejsze
            to ożywienie Koncepcji Na Sukces jako 3 liter: KNS i ich rozwinięcia
            w zależności od tematyki 😉
          </StyledParagraph>
          <StyledParagraph>
            <HighlightedText>Kamil na Social</HighlightedText> - KNS czyli Vlogi
            i kanał na Youtubie z dniem startu 11.12.2019. Dowiemy się tutaj
            wszystkiego co dotyczy twórcy i założyciela Koncepcji Na Sukces.
            Tematyka vlogów jest bardzo Szeroka od Recenzji i Opinii Gal
            Bokserskich oraz zawodników przez różnego rodzaju zarabianie w
            Internecie zaczynając od drobncyh, po duże inwestycję, a wszystko
            powiązane jest najobszerniejszym tematem jakim jest Marketing w
            Social Mediach.
          </StyledParagraph>
          <StyledParagraph>
            <HighlightedText>Koksze na Siłce</HighlightedText> - Czyli kolejne
            rozwinięcie skrótu KNS ale tematyka jest głębsza, ponieważ tematy
            poruszane są z różnych dziedzin sportu. Zobaczyć tutaj będziecie
            mogli moje trzy oblicza: Dietetyk, Trener i Zawodnik.
          </StyledParagraph>
        </StyledSection>
        <StyledWideSection>
          <AuthorInfoBox />
        </StyledWideSection>

        <section>
          <StyledHeading>Najnowsze wpisy</StyledHeading>
          <RecentPostBox />
        </section>
      </GridView>
    </Layout>
  );
};

export default AboutPage;
