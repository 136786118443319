import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useImg } from 'hooks/useImg';

const Wrapper = styled.div`
  width: 90%;
  display: flex;
  background-color: ${({ theme }) => theme.color.light2};
  padding: 3rem calc((100% - 128rem) / 2) 3rem calc((90% - 128rem) / 2);
  margin-left: auto;
  border-radius: 2rem 0 0 2rem;
  box-shadow: ${({ theme }) => theme.shadow.borderShadowLight};

  & * {
    color: ${({ theme }) => theme.color.main};
  }

  h3,
  p {
    grid-column: 2 / 3;
  }

  h3 {
    text-align: left;
    align-self: end;
    ${({ theme: { mq } }) => mq.tablet} {
      font-size: ${({ theme: { fontSize } }) => fontSize.l};
    }
  }

  ${({ theme: { mq } }) => mq.bigTablet} {
    flex-direction: column;
  }
  ${({ theme: { mq } }) => mq.tablet} {
    width: 100%;
    border-radius: 0;
  }
`;

const StyledAuthorImg = styled(Img)`
  border-radius: 50%;
  margin-left: 5rem;
  margin-right: 10rem;
  width: 40rem;
  height: 40rem;
  flex-basis: 40rem;
  flex-shrink: 0;
  align-self: center;
  background: currentColor;
  border: 2rem solid currentColor;
  box-sizing: content-box;
  box-shadow: ${({ theme }) => theme.shadow.borderShadow2};

  ${({ theme: { mq } }) => mq.tablet} {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
    text-align: justify;
    /* ${({ theme: { mq } }) => mq.tablet} {
      font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
    } */
  }

  ${({ theme: { mq } }) => mq.desktop} {
    padding-right: 5rem;
  }
  ${({ theme: { mq } }) => mq.bigTablet} {
    padding: 0 10rem;
    h3 {
      align-self: flex-start;
    }
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    padding: 0 5rem;
  }
`;

const StyledExternalLink = styled.a`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
  color: ${({ theme: { color } }) => color.fb};
`;

const AuthorInfoBox = () => {
  const { authorPhoto } = useImg();
  return (
    <Wrapper>
      <StyledAuthorImg fluid={authorPhoto} />
      <ContentBox>
        <h3>Więcej o autorze</h3>
        <p>
          Cześć mam na Imię Kamil. Sportem interesuje się od dziecka, chociaż
          sport nie zawsze interesował się mną 😅, więc tak w wielkim skrócie
          stałem się tytanem ciężkiej pracy na treningach, aby zacząć osiągać
          Sukces w różnych jego dziedzinach. Zarabianiem pieniędzy zacząłem
          interesować się już w Gimnazjum(tak pochodzę z tego pokolenia), a
          pierwszym Biznesem było sprowadzenie telefonów (Nokia) z USA i
          sprzedaż w Polsce. Jak się później okazało to właśnie wtedy poszedł
          pierwszy kamyczek w lawinie zdarzeń, aby również na tej gałęzi zacząć
          osiągać Sukces.
        </p>
        <h3>Człowiek renesansu..</h3>
        <p>
          Od zawsze czego bym nie robił szukałem najlepszej Koncepcji, aby
          osiągnąć w tym Sukces, a uwierz mi próbowałem wszystkiego przez co
          często ludzie śmiali się nazywając mnie Da Vinci naszych czasów.
        </p>
        <h3>Dlaczego powstał ten blog?</h3>
        <p>
          Musiało upłynąć wiele lat zanim zacząłem wdrażać to co uznawałem za
          odpowiednią Koncepcję Na Sukces. Efektem tego była utworzona{' '}
          <StyledExternalLink
            href='https://www.facebook.com/KoncepcjaNaSukces'
            target='_blank'
            rel='noreferrer'
          >
            fanpage
          </StyledExternalLink>{' '}
          (2017 rok) w której zacząłem dzielić się swoją wiedzą, a kolejnym
          krokiem jest właśnie ten Blog, który będzie zbiorem wszystkiego co
          najważniejsze w mojej działalności.
        </p>
      </ContentBox>
    </Wrapper>
  );
};

export default AuthorInfoBox;
