import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import styled from 'styled-components';

import Logo from 'images/svg/logo-kns.svg';

const StyledWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  svg {
    width: 100%;
  }
`;
const AnimatedLogo = () => {
  gsap.registerPlugin(ScrollTrigger);
  const wrapper = useRef(null);

  useEffect(() => {
    const [elements] = wrapper.current.children;

    const background = elements.getElementById('background');
    const text = elements.getElementById('text');
    const line = elements.getElementById('line');
    const bars = elements.getElementById('bars');
    const flame = elements.getElementById('flame');
    const rocket = elements.getElementById('rocket');
    gsap.set(
      [background, ...text.children, line, ...bars.children, flame, rocket],
      {
        autoAlpha: 0,
      },
    );
    gsap.set(line, { transformOrigin: 'right' });
    gsap.set([...bars.children], { transformOrigin: 'bottom', scaleY: 0 });

    const tl = gsap.timeline();
    tl.to(background, { duration: 0.3, autoAlpha: 1 })
      .fromTo(line, { scaleX: 0 }, { duration: 0.5, scaleX: 1, autoAlpha: 1 })
      .to([...bars.children], {
        duration: 0.75,
        scaleY: 1,
        autoAlpha: 1,
        stagger: 0.25,
      })
      .to(
        [...text.children],
        { duration: 1.5, autoAlpha: 1, stagger: 0.25 },
        '-=1.15',
      )
      .fromTo(
        rocket,
        { x: '-=200', y: '+=50' },
        { duration: 0.5, x: 0, y: 0, autoAlpha: 1 },
        '-=1',
      )
      .to(flame, { duration: 0.5, autoAlpha: 1 }, '-=0.5');

    ScrollTrigger.create({
      animation: tl,
      trigger: wrapper.current,
      start: 'top center',
      end: 'center top',
    });
  });

  return (
    <StyledWrapper ref={wrapper}>
      <Logo />
    </StyledWrapper>
  );
};

export default AnimatedLogo;
